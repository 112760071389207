// Fix
*{
  outline: none;
}

// Globals
.menu li a{
  font-size: 18px;
}

.padding{
  padding: 2rem 0rem;
}

// Section
.cream{
  background-color: #FCDBD1;
}

.cream .grid-x{
  min-height: 100vh;
}

// Logo
.logo{
  font-family: 'Modak', cursive;
}

.cream__content{
  clear: both;
  position: relative;
}

.ice{
  max-width: 250px;
  @include breakpoint(xlarge) {
    max-width: 280px;
  }
  @include breakpoint(large only) {
    max-width: 250px;
  }
  @include breakpoint(medium only) {
    max-width: 225px;
  }
  @include breakpoint(small only){
    max-width: 175px;
  }
  padding: 0rem 0rem 2rem;
}

h1{
  letter-spacing: 1px;
  line-height: 0.8!important;
  -webkit-text-stroke-width: 1px!important;
  -webkit-text-stroke-color: black!important;
}

a.cta{
  font-size: 22px;
  letter-spacing: 2px;
}

// Section
.brands{
  border-top: 2px solid $black;
  border-bottom: 2px solid $black;
}

.brands .grid-x{
  min-height: 100vh;
}

.brand{
  position: relative;
  background-size: cover!important;
  background-position: center;
}

.brand:first-child{
  border-right: 1px solid $black;

  @include breakpoint(small only){
    border-bottom: 2px solid $black;
  }
}

.brand:last-child{
  border-left: 1px solid $black;
}

.brand__overlay{
  width: 100%; 
  height: 100%; 
  position: absolute;
  opacity: 0.90;
  top: 0; 
  left: 0;
}

.brand__content{
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 100%; 
  text-align: center;
  z-index: 1;
  position: relative;
  padding: 0rem 0rem 3rem;
}

figure{
  display: inline-block!important;
}